import { combineReducers } from 'redux';
import { call } from 'redux-saga/effects';
import * as ManagePin from './managePin';
import * as ParentalBadges from './parentalBadges';

export default combineReducers({
  managePin: ManagePin.default,
  parentalBadges: ParentalBadges.default,
});

export const sagas = [call(ManagePin.saga)];
